<template>
  <div>
    <CommonTopBanner
      title="Built to protect. Designed to impress."
      sub-title="Elevating your home's protection with our expert roofing services, ensuring lasting quality and peace of mind."
      cta-text="Free Roofing Estimate"
      :banner-features="['1 Day Roof Installs', 'Best-In-Class Metal and Synthetic Materials', 'Licensed & insured', 'Roofing consultations','Veteran operated', 'Free estimates & quotes']"
    />
    <LazyCommonCertifiedBy />
    <LazyMainIntroduction />
    <CommonCustomerSaying />
    <LazyMainTeamMember />
    <LazyCommonOneStopRoofing />
    <LazyMainNewsAndArticle />
  </div>
</template>

<script setup lang="ts">
useHead({
  title: 'Canopy Roofing - Home'
})
useSeoMeta({
  title: 'Canopy Roofing - Home',
  ogTitle: 'Canopy Roofing - Home',
  description: 'Canopy Roofing serving the Atlanta area.',
  ogDescription: 'Canopy Roofing serving the Atlanta area.'
  // ogImage: 'https://example.com/image.png',
  // twitterCard: 'summary_large_image'
})
</script>
